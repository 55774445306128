
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import moment from "moment";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { VueCookieNext } from "vue-cookie-next";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "fieldVisit_drawer",
  components: {
    Field,
  },
  data() {
    return {
      api_url: "",
      visitorType: [],
      association: [],
      instituteList: [],
      employeeList: [],
      load: false,
      loading: false,
      instituteDetails: [] as any,
      formData: {
        id: "",
        concerned_entity_info_id: "",
        visited_entity_info_id: "",
        visited_institute_info_id: "",
        visited_date: "",
        visiting_officer_id: "",
        date_of_submission: "",
        visited_institute_address: "",
        visitor_type_id: "",
      },
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;

    this.emitter.on("field-visit-update", async (data) => {
      await this.getFieldVisit(data);
      await this.getVisitorTypes();
      await this.getEmployee();
      await this.getAssociation();
      await this.getTrainingInstitute();

    });
  },
  methods: {
    async formSubmit() {
      let data = new FormData();
      for (var key in this.formData) {
        data.set(key, this.formData[key]);
      }
      let user_id = VueCookieNext.getCookie('_seip_user');
      user_id =  user_id.id;
      data.set('user_id',user_id);
      this.loading = true;
      ApiService.post("fieldvisit/update", this.formData)
        .then((response) => {
          if (response?.data?.status == "success") {
            Swal.fire({
              title: "Success",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(()=>{
              DrawerComponent?.hideAll();
              this.loading = false;
              this.emitter.emit("field-list",true);
            })
          } else {
            this.loading = false;
            Swal.fire({
              title: "Error Found",
              html: response.data.message,
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getFieldVisit(id) {
      this.load = true;
      ApiService.get("fieldvisit/show/" + id)
        .then((response) => {
          this.load = false;
          this.formData.id = response.data.data.id;
          this.formData.concerned_entity_info_id =
            response.data.data.concerned_entity_info_id;
          this.formData.visited_entity_info_id =
            response.data.data.visited_entity_info_id;
          this.formData.visited_institute_info_id =
            response.data.data.visited_institute_info_id;
          this.formData.visited_date = response.data.data.visited_date;
          this.formData.date_of_submission =
            response.data.data.report_of_submission;
          this.formData.visited_institute_address =
            response.data.data.visited_institute_address;
          this.formData.visitor_type_id = response.data.data.visitor_type_id;
          this.formData.visiting_officer_id = response.data.data.employee_id;
        })
        .catch((response) => {
          this.load = false;
          console.log(response);
        });
    },
    async getVisitorTypes() {
      let entity_type_id = VueCookieNext.getCookie("_seip_entity_type");
      await ApiService.get("fieldvisit/type?entity_type_id=" + entity_type_id)
        .then((response) => {
          console.log(response);
          this.visitorType = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.association = response.data.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          "?entity_id=" +
          this.formData.visited_entity_info_id
      )
        .then((response) => {
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInsituteDetails() {
      await ApiService.get(
        "institute/show/" + this.formData.visited_institute_info_id
      )
        .then((response) => {
          this.instituteDetails = response.data.data;
          this.formData.visited_institute_address =
            this.instituteDetails.address;
          console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getEmployee() {
      let entity_type_id = VueCookieNext.getCookie("_seip_entity_type");
      let entity_id = VueCookieNext.getCookie("_entity_id");
      await ApiService.get(
        "fieldvisit/employeeList?entity_type_id=" +
          entity_type_id +
          "&entity_id=" +
          entity_id +
          "&visitor_type_id=" +
          this.formData.visitor_type_id
      )
        .then((response) => {
          console.log(response);
          this.employeeList = response.data.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },
  },
});
