
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/kTDatatableLeft.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import { VueCookieNext } from "vue-cookie-next";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import KTDrawerFieldVisitUpdate from "@/layout/header/partials/field-visit/fieldVisitUpdateDrawer.vue";
import { apiEndpoint } from "@/mixin/apiEndpoint";
import moment from 'moment';
export default defineComponent({
  name: "field-visit-list",
  components: {
    Form,
    Field,
    Datatable,
    KTDrawerFieldVisitUpdate,
  },
  data() {
    return {
      batch: {
        visitor_type: []as any,
        visit_type: [] as any,
      },
      employee: {},
      moment: '' as any,
      visitorType: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Visited Institute Name",
          key: "ins_name",
          sortable: true,
          width: "180px",
        },
        {
          name: "Visited Institute Address",
          key: "institute_address",
          sortable: true,
          width: "200px",
        },
        {
          name: "Visited Institute District",
          key: "institute_district",
          sortable: true,
          width: "180px",
        },
        {
          name: "Visited Association/Partner",
          key: "association",
          sortable: true,
        },
        {
          name: "Date of Visit",
          key: "date_of_visit",
          sortable: true,
          width: "180px",
        },
        {
          name: "Officer Name",
          key: "officer_name",
          sortable: true,
          width: "180px",
        },
        {
          name: "officer organization",
          key: "officer_organization",
          sortable: true,
        },
        {
          name: "officer Designation",
          key: "officer_designation",
          sortable: true,
          width: "180px",
        },
      ],
      visitList: [],
      tableData: [],
         visitortypes: [] as any,
      VisitorTypeLabel: [] as any,
      loading: false,
      load: false,
      courseInfoData: false,
      componentKey: 0,
      showTable: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.moment = moment;
    this.emitter.on("field-list", async () => {
      this.getVisitList();
    })
    await this.getVisitorTypes();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getVisitorTypes() {
      let entity_type_id = VueCookieNext.getCookie("_seip_entity_type");
      await ApiService.get("fieldvisit/type?entity_type_id=" + entity_type_id)
        .then((response) => {
          console.log(response);
          this.visitorType = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
     visitorTypeLabelChange(data) {
      this.VisitorTypeLabel = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.visitortypes.length; j++) {
          if (data[i] == this.visitortypes[j].id) {
            this.VisitorTypeLabel.push(this.visitortypes[j].name);
          }
        }
      }
    },
    async getVisitList() {
      this.load = true;
      let entity_id = "";
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
        ApiService.get("fieldvisit/list?visitor_type=" + this.batch.visitor_type + '&entity_id=' + entity_id + '&visit_type=' + this.batch.visit_type)
          .then((response) => {
            this.visitList = response.data.data;
            this.componentKey += 1;
            this.showTable = true;
            this.load = false;
          })
          .catch((response) => {
            console.log(response);
            this.load = false;
          });
    },
    edit(data) {
      this.emitter.emit("field-visit-update", data);
    },
    Delete(id) {
      Swal.fire({
        title: "Warning!",
        html: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.load = true;
          ApiService.delete("fieldvisit/delete/" + `${id}`)
            .then((response) => {
              this.load = false;
              Swal.fire({
                title: "Deleted!",
                text: response.data.data,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {
                this.emitter.emit("field-list", true);
              });

            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
